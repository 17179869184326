import styled from "styled-components/macro"
import { XNav } from '@ximdex/xui-react/material'

export const StyledXNav = styled(XNav)` 
    margin-bottom: 30px;
    box-shadow: 0px 1px 2px -1px rgb(249 251 252 / 10%), 0px 2px 5px 0px rgb(249 251 252 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
    background-color: #ffffff !important;

    & div {
        color: rgba(229, 27, 35, 0.87);
        font-weight: bold;

        & :hover {
            color: rgba(229, 27, 35, 1);
        }
    };
`;