const navigatorLngISO639 = window.navigator.language.slice(0, 2)

const availableLngs = ['es', 'en', 'ca']

const navigatorLang = availableLngs.includes(navigatorLngISO639) ? navigatorLngISO639 : 'es'

const currentLang = sessionStorage.getItem("currentLang")

const es = {
    loginPage: require("./es/loginPage.json"),
    homePage: require("./es/homePage.json"),
    generatorView: require("./es/generatorView.json"),
    createGenerateView: require("./es/createGenerateView.json"),
    examView: require("./es/examView.json"),
}

const en = {
    loginPage: require("./en/loginPage.json"),
    homePage: require("./en/homePage.json"),
    generatorView: require("./en/generatorView.json"),
    createGenerateView: require("./en/createGenerateView.json"),
    examView: require("./en/examView.json"),
}

const ca = {
    loginPage: require("./ca/loginPage.json"),
    homePage: require("./ca/homePage.json"),
    generatorView: require("./ca/generatorView.json"),
    createGenerateView: require("./ca/createGenerateView.json"),
    examView: require("./ca/examView.json"),
}

const getConfigLanguages = () => {
    return {
        interpolation: { escapeValue: false },
        lng: currentLang || navigatorLang,
        resources: {
            es: es,
            en: en,
            ca: ca,
        }
    }
}

export default getConfigLanguages;