import React from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'isomorphic-dompurify';
import { 
    StyledXCard, 
    ActivityContainer, 
    IdParagraph,
    TitleParagraph,
    TextParagraph,
} from './StyledActivitiesList';
import { XPagination } from '@ximdex/xui-react/material';

const ActivitiesList = ({
    activities,
    setActivities,
}) => {
    const [_t] = useTranslation("generatorView");
    
    return (
        <StyledXCard
            isCollapsable={true}
            isCollapsed={true}
            title={_t("potential_activities").replace("{num_potentials_activities}", activities?.total ?? 0)}
        >
            {activities?.data?.length > 0 ? (
                <>
                    {activities.data.map(activity => (
                        <ActivityContainer key={activity?.id}>
                            <IdParagraph>{activity?.id}</IdParagraph>
                            <TitleParagraph>{activity?.title}</TitleParagraph>
                            <TextParagraph dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity?.text) }} />
                        </ActivityContainer>
                    ))}
                    <XPagination 
                        paginationMetaData={activities} 
                        setPaginationMetaData={setActivities} 
                        showingPageText={[_t("showing_page"), _t("of")]} 
                    />
                </>
            ) : (
                <p>{_t("messages.no_activities_available")}</p>
            )}
        </StyledXCard>
    );
};

export default ActivitiesList;
