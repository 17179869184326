import { saveAs } from 'file-saver';
import {
    Document, HeadingLevel, Packer, Paragraph, SectionType,
    TextRun, AlignmentType, Table, TableRow, TableCell, WidthType,
    TextWrappingType, TextWrappingSide, BorderStyle,
    ImageRun, PageNumber, Header, Footer
} from "docx";
import { XDAM, ACTIVITIES_OPEN_QUESTION } from "./../../CONSTANTS";

const optionsNumberings = [];

export const DocExam = async (props, tables, showCorrectResults, setIsLoadingDOC, setError) => {
    const { title, activities } = props;

    const header_first_page = new Table({
        columnWidths: [4505, 4505],
        rows: [
            new TableRow({
                children: [
                    new TableCell({
                        width: { size: 4505, type: WidthType.DXA },
                        borders: {
                            top: {style: BorderStyle.NONE, size: 0}, bottom: {style: BorderStyle.NONE, size: 0},
                            left: {style: BorderStyle.NONE, size: 0}, right: {style: BorderStyle.NONE, size: 0},
                        },
                        children: [new Paragraph({ text: props?.name, style: "mcgrawhills"})],

                    }),
                    new TableCell({
                        width: { size: 4505, type: WidthType.DXA },
                        borders: {
                            top: {style: BorderStyle.NONE, size: 0}, bottom: {style: BorderStyle.NONE, size: 0},
                            left: {style: BorderStyle.NONE, size: 0}, right: {style: BorderStyle.NONE, size: 0},
                        },
                        children: [new Paragraph({ text: props?.surname, style: "mcgrawhills"})],
                    }),
                ],
            }),
            new TableRow({
                children: [
                    new TableCell({
                        width: { size: 4505, type: WidthType.DXA },
                        borders: {
                            top: {style: BorderStyle.NONE, size: 0}, bottom: {style: BorderStyle.NONE, size: 0},
                            left: {style: BorderStyle.NONE, size: 0}, right: {style: BorderStyle.NONE, size: 0},
                        },
                        children: [new Paragraph({ text: props?.date, style: "mcgrawhills"})],
                    }),
                    new TableCell({
                        width: { size: 4505, type: WidthType.DXA },
                        borders: {
                            top: {style: BorderStyle.NONE, size: 0}, bottom: {style: BorderStyle.NONE, size: 0},
                            left: {style: BorderStyle.NONE, size: 0}, right: {style: BorderStyle.NONE, size: 0},
                        },
                        children: [new Paragraph({ text: props?.course, style: "mcgrawhills"})],
                    }),
                ],
            }),
        ],
    });

    const parseHtml = (info) => {
        const div = document.createElement("div");
        div.innerHTML = info;
        const text = div.textContent || div.innerText || "";
        return text;
    };

    const createTitle = (title, index) => {
        return new Paragraph({
            text: index + 1 + ". " + title,
            color: "black",
            heading: HeadingLevel.HEADING_3,
            spacing: {
                before: 300,
                after: 50,
            },
        });
    }

    const createContent = (text) => {
        const content = document.createElement("div");
        content.innerHTML = text;

        return Array.from(content.childNodes).map(node => {
            if (node.tagName === "TABLE") {
                return createTable(node);
            }

            if (
                (node.textContent && node.textContent.trim().length > 0) ||
                (node.innerText && node.innerText.trim().length > 0)
            ) {
                return new Paragraph({
                    text: node.textContent || node.innerText,
                    style: "mcgrawhills",
                    color: "black",
                    spacing: {
                        before: 100,
                        after: 100,
                    },
                    font: "Verdana",
                    size: 28,
                });
            }

            return null;
        }).filter(t => t);
    }

    const getImageDimensions = (activitiyText, encodedImage) => {
        const findImageNode = (activitiyText, encodedImage) => {
            const div = document.createElement("div");
            div.innerHTML = activitiyText;
    
            const imageNode = Array.from(div.childNodes).find((child) => {
                return (
                    child?.src === encodedImage ||
                    Array.from(child.childNodes).some(
                        (grandChild) => grandChild?.src === encodedImage
                    )
                );
            });
    
            if (imageNode.tagName === "IMG") return imageNode;

            return Array.from(imageNode.childNodes).find(
                (child) => child?.src === encodedImage
            );
        }

        const imageNode = findImageNode(activitiyText, encodedImage);

        if (imageNode) {
            return {
                width: imageNode?.width || 300,
                height: imageNode?.height || 300,
            };
        }

        return new Promise(function (resolved, _) {
            const i = new Image();
            i.onload = function () {
                resolved({ width: i.width, height: i.height });
            };
            i.src = encodedImage;
        });
    }

    const createImage = async (image, transformation = {width: 150, height: 150} ) => {
        const blob = await fetch(`${image.startsWith('@@@') ? XDAM : ''}${image}`)
            .then(res => res.blob())
            .catch(err => console.error('createImage: ', err));

        return new ImageRun({
            data: blob,
            transformation
        });
    }

    const createOptionImage = async (image) => 
        createImage(image, {   
            width: 150,
            height: 150,
        });

    const createActivityImage = async (image) => {
        return createImage(image?.src || image, {width: image?.width || 200, height: image?.height || 200});
    }

    const addSpaceForOpenAnswer = async () => {
        return new Paragraph({
            children: [
                new TextRun({
                    text: '', 
                    break: 2
                })
            ]
        });
    };

    const listActivityOpenAnswer = async (answer, id) => {
        return new Paragraph({
            children: [
                new TextRun({text: '✔ ', color: "#00ff00"}),
                new TextRun(parseHtml(answer))
            ],
            spacing: {
                before: 200,
                // after: 100,
            },
            style: "mcgrawhills",
            indent: {
                left: 720,
            }, 
        });
    };

    const listActivityOptions = async (options, id) => {

        // const timestamp = Date.now();

        optionsNumberings.push({
            reference: `mcgrawhills_${id}`,
            levels: [
                {
                    level: 0,
                    format: "lowerLetter",
                    text: "%1)",
                    alignment: AlignmentType.LEFT,
                    style: {
                        paragraph: {
                            indent: { left: 380, hanging: 360 },
                        },
                    },
                    font: "Verdana",
                },
            ]
        })

        return await Promise.all(options.map(async (option) => {

            const image = [];
            const after = [];

            if (option.image) {
                image.push(await createOptionImage(option.image));
                image.push(
                    new TextRun({
                        text: "",
                        break: 1,
                    })
                );

                after.push(
                    new TextRun({
                        text: "",
                        break: 1,
                    })
                );
            }

            if (option?.formulasWithDetails?.length > 0) {
                await Promise.all(
                    option.formulasWithDetails.map(async(formula) => {
                    parseHtml(option.title) && image.push(
                        new TextRun({
                            text: "",
                            break: 1,
                        })
                    );
                    image.push(await createActivityImage(formula))
                    image.push(
                        new TextRun({
                            text: "",
                            break: 1,
                        })
                    );
                }));
            };

            const text = new TextRun(parseHtml(option.title))

            const correctionIcon = showCorrectResults 
                ? new TextRun({
                    text: option.is_right === 'TRUE' ? '✔ ' : '✘ ',
                    color: option.is_right === 'TRUE' ? "#00ff00" : "#ff0000",
                }) 
                : new TextRun('')

            return new Paragraph({
                children: [
                    correctionIcon,
                    text,
                    ...image,
                    ...after
                ],

                numbering: {
                    level: 0,
                    reference: `mcgrawhills_${id}`
                },
                indent: {
                    left: 720,
                }, 

                style: (option.image || option.formulasWithDetails) ? "": "mcgrawhills",


            });
        })) ;
    };

    const createTable = (table) => {
        const parsedTables = Array.from(table.children).flatMap((tbody) => {
            return Array.from(tbody.children).map((row) => {
                return Array.from(row.children).map(
                    (cell) => cell.innerHTML
                );
            });
        });

        if (parsedTables.length > 0) {
            return new Table({
                width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                },
                columnWidths: new Array(parsedTables.length).fill(
                    100 / parsedTables.length
                ),
                rows: parsedTables.map(
                    (row) =>
                        new TableRow({
                            children: row.map(
                                (cell) =>
                                    new TableCell({
                                        children: [new Paragraph({ 
                                            text: cell, 
                                            style: "cell"
                                        })],
                                    })
                            ),
                        })
                ),
            });
        }
    }

    const sectionsPromises = activities.map(async (activity, index) => {
        const section = [];

        if (index === 0) section.push(header_first_page);

        section.push(createTitle(activity.title, index));
        section.push(...createContent(activity?.text));

        if (activity.image) {
            section.push(
                new Paragraph({
                    text: "",
                    children: [await createActivityImage(activity.image)],
                    spacing: {
                        before: 50,
                        after: 50
                    },
                    alignment: AlignmentType.CENTER,
                })
            );
        }

        if (activity?.formulasWithDetails?.length > 0) {
            section.push(
                new Paragraph({
                    children: await Promise.all(
                        activity?.formulasWithDetails?.map(
                            async (formula) =>
                                await createActivityImage(formula),
                        )
                    ),
                    spacing: {
                        before: 50,
                        after: 50
                    },
                    alignment: AlignmentType.CENTER,
                })
            );
        }

        if (ACTIVITIES_OPEN_QUESTION?.includes(activity?.type)) {
            const openAnswer = activity?.targets?.[0]?.accepted_values?.join(', ')
            if (!showCorrectResults || !openAnswer) section.push(await addSpaceForOpenAnswer());
            if (showCorrectResults) section.push(await listActivityOpenAnswer(openAnswer, activity?.id));
        } else {
            section.push(...(await listActivityOptions(activity.options, activity?.id)));
        };
        

        return {
            properties: {
                type: SectionType.CONTINUOUS
            },
            headers: {
                default: new Header({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            heading: HeadingLevel.HEADING_1,
                            indent: {
                                left: 980,
                            },
                            border: {
                                bottom: {
                                    color: "auto",
                                    space: 1,
                                    style: "single",
                                    size: 1,
                                },
                            },
                            children: [
                                new ImageRun({
                                    data: await fetch('/images/logo-mhe.png').then(res => res.blob()),
                                    transformation: {
                                        width: 55,
                                        height: 55,
                                    },
                                    floating: {
                                        horizontalPosition: {
                                            offset: 941440, // relative: HorizontalPositionRelativeFrom.PAGE by default
                                        },
                                        verticalPosition: {
                                            offset: 151440, // relative: VerticalPositionRelativeFrom.PAGE by default
                                        },
                                        wrap: {
                                            type: TextWrappingType.SQUARE,
                                            side: TextWrappingSide.BOTH_SIDES,
                                        },
                                        margins: {
                                            bottom: 101440,
                                        }
                                    },                                
                                }),
                                new TextRun({
                                    text: title,
                                    color: '#808080'
                                })
                            ]
                        })
                    ],
                }),
            },
            children: section,
            footers: {
                default: new Footer({
                    children: [
                        new Paragraph({
                            alignment: AlignmentType.RIGHT,
                            children: [
                                new TextRun({
                                    children: [PageNumber.CURRENT, "-", PageNumber.TOTAL_PAGES]
                                })
                            ]
                        }),
                    ],
                }),
            },
        };
    });

    const doc = new Document({
        styles: {
            default: {
                heading1: {
                    run: {
                        color: "000000",
                        font: "Verdana",
                        size: 28,
                    },
                },
                heading3: {
                    run: {
                        color: "000000",
                        font: "Verdana",
                        size: 22,
                        bold: true,
                        spacing: {
                            before: 50,
                            after: 50,
                        }
                    },
                },
                listParagraph: {
                    run: {
                        font: "Verdaba",
                        size: 22,
                    },
                },
            },
            paragraphStyles: [
                {
                    id: "mcgrawhills",
                    name: "mcgrawhills",
                    basedOn: "Normal",
                    next: "Normal",
                    run: {
                        font: "Vernada",
                        size: 22,
                    },
                    paragraph: {
                        spacing: {
                            // line: 376,
                            before: 50,
                            after: 50,
                        }
                    }
                },
                {
                    id: "cell",
                    name: "cell",
                    basedOn: "mcgrawhills",
                    next: "Normal",
                    run: {
                        font: "Verdana",
                        size: 22,
                    },
                    paragraph: {
                        spacing: {
                            before: 50,
                            after: 50,
                        },
                    }
                },
            ]
        },
        numbering: {
            config: optionsNumberings
        },
        sections: [
            ...await Promise.all(sectionsPromises)
                .catch(err => {
                    setIsLoadingDOC(false); 
                    setError(prevState => ({...prevState, doc: true}))
                    console.error(`Error Promise.all sections: ${err}`); 
                }),
        ]
    });
    Packer.toBlob(doc)
        .then((blob) => {
            saveAs(blob, title + '.docx');
            setError(prevState => ({...prevState, doc: false}))
        })
        .catch((error) => {
            setIsLoadingDOC(false)
            setError(prevState => ({...prevState, doc: true}))
            console.error('Error Packer.toBlob: ', error)
        })
        .finally(()=> {
            setIsLoadingDOC(false)
        });
}
