import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import {
    ExamTitle,
    ActivityTitle,
    ActivityList,
    ActivityContent,
    ExamContainer,
    ActivityContainer,
    ActivityListElement,
    ExamHeader,
    ExamContent,
    ButtonsContainer,
    ActivityImage,
    OptionImage,
    SwitchContainer,
    CorrectAnswer,
    StyledXLabel,
    ActivityActionsButtons,
    XboxExman,
    ButtonMove,
    ContainerButtonsMove,
    LoadContainer,
    CorrectionSymbol,
} from './StyledExam';
import DOMPurify from 'isomorphic-dompurify';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfExam from '../PdfExam/PdfExam';
import { DocExam } from '../DocExam/DocExam';
import { XButton, XSwitch, XSpinner } from '@ximdex/xui-react/material';
import { XDAM, ACTIVITIES_OPEN_QUESTION, ACTIVITIES_CLOSED_QUESTION } from './../../CONSTANTS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faShuffle, faArrowCircleUp, faArrowCircleDown, faSpinner, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { getRandonActivity } from '../../services/exam.service'

const Exam = ({ isbn, units, language_default, oc_type, data, setActivities }) => {
    const [_t] = useTranslation("examView")

    const [tables, setTables] = useState([]);
    const [showCorrectResults, setShowCorrectResults] = useState(false);
    const [dataFormated, setDataFormated] = useState(null)
    const [activitiesToReload, setActivitiesToReload] = useState([])
    const [isLoadingDOC, setIsLoadingDOC] = useState(false)
    const [error, setError] = useState({
        doc: false, 
        pdf: false
    })

    useEffect(() => {
        formatActivities();
    }, [data]);

    const handleDownload = ({ blob, url, loading, error }) => {
        return (loading ? 'Cargando el documento...' : 'Descargar')
    }

    const handleDownloadDOC = (dataFormated, tables, showCorrectResults) => {
        setIsLoadingDOC(true)
        DocExam(dataFormated, tables, showCorrectResults, setIsLoadingDOC, setError)
    }

    const parseText = (activity) => {
        // replace font-family
        activity.text = activity.text.replace(/font-family:.*;/, 'Verdana;');

        if (activity?.options?.length > 0) {
            activity?.options?.forEach((option, index) => {
                activity.options[index].title = option?.title?.replace(/font-family:.*;/, 'Verdana;')
            })
        };

        // create text preview 
        activity.text_preview = activity.text.replace(/<[^>]+>/g, ' ').trim();

        if (activity?.options?.length > 0) {
            activity?.options?.forEach((option, index) => (
                activity.options[index].title_preview = option?.title?.replace(/<[^>]+>/g, ' ').trim()
            ))
        };

        return activity;
    }    
    
    const getNewSrcWithDetails = (sources) => {
        const newSrc = sources.map(x => x.replace(/.*src="([^"]*)".*/, '$1'));
        let width = sources.map(x => x.replace(/.*width="([^"]*)".*/, '$1'));
        let height = sources.map(x => x.replace(/.*height="([^"]*)".*/, '$1'));    

        if ((width[0] === 'auto' && height[0] === 'auto') || parseInt(height[0], 10) > 200) {
            height = [ 200 ]
        }

        return {
            formulasWithDetails: [{
                src: newSrc[0],
                width: parseInt(width[0], 10) || 200,
                height: parseInt(height[0], 10) || 200,
            }],
            formulas: [ 
                newSrc[0]
            ]
        }
       
    }
    
    const searchFormula = (activity) => {
        const sources = activity.text.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
        if (sources?.length > 0) {
            const newSrcWithDetails = getNewSrcWithDetails(sources)

            activity.formulas = newSrcWithDetails.formulas
            activity.formulasWithDetails = newSrcWithDetails.formulasWithDetails

            activity.warning = _t("messages.alert_activity_images")
            console.warn(_t("messages.alert_activity_images"), activity.text)
        };

        return activity;
    }

    const searchOptionsFormula = (activity) => {
        activity?.options?.forEach((option, optionIndex) => {
            const sources = option?.title?.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
            if (sources?.length > 0) {
                const newSrcWithDetails = getNewSrcWithDetails(sources)

                activity.options[optionIndex].formulas = newSrcWithDetails.formulas
                activity.options[optionIndex].formulasWithDetails = newSrcWithDetails.formulasWithDetails

                activity.warning = _t("messages.alert_activity_images")
                console.warn(_t("messages.alert_activity_images"), activity.options[optionIndex].title)
            }
        });

        return activity;
    }

    const getTables = (activity, index) => {
        activity.text = activity.text.replace('<table', `<table data-activity=${index}`);
        const tables = document.getElementsByTagName('table');
        setTables(tables);
    }

    const formatActivities = () => {
        const examDataCopy = { ...data };
        // conditional code added for testing purposes only
        // if (examDataCopy.activities[0].type !== 'input') {
        //     examDataCopy.activities.unshift({
        //         "assessments": [],
        //         "available_languages": [],
        //         "background": null,
        //         "categories": null,
        //         "created_at": "2021-04-06T13:57:41",
        //         "criterion_id": null,
        //         "current_language": "es-ES",
        //         "custom_client_id": null,
        //         "feedbacks": [
        //             {
        //                 "success_percentage": 0,
        //                 "text": "Sorry, your answer is not correct."
        //             },
        //             {
        //                 "success_percentage": 100,
        //                 "text": "Well done!"
        //             }
        //         ],
        //         "id": 209,
        //         "image": null,
        //         "isbn": [],
        //         "language_default": "es-ES",
        //         "level": 1,
        //         "matrix": null,
        //         "meta_activity": null,
        //         "meta_data": [],
        //         "name": "209-Activity",
        //         "options": [],
        //         "props": null,
        //         "status": "ACTIVE",
        //         "tags": null,
        //         "targets": [
        //             {
        //                 "accepted_values": [
        //                     "cell"
        //                 ],
        //                 "area": null,
        //                 "id": 7746,
        //                 "image": null,
        //                 "image_url": null,
        //                 "order": 1,
        //                 "text": null,
        //                 "title": null,
        //                 "translatable_fields": [
        //                     "title",
        //                     "text",
        //                     "accepted_values",
        //                     "unaccepted_values"
        //                 ],
        //                 "unaccepted_values": []
        //             }
        //         ],
        //         "text": "What is the name of the smallest unit of life?",
        //         "text_color": null,
        //         "title": "Write your answer below",
        //         "translatable_fields": [
        //             "title",
        //             "text",
        //             "name",
        //             "feedbacks",
        //             "options",
        //             "targets"
        //         ],
        //         "type": "input",
        //         "units": [],
        //         "updated_at": "2021-04-06T14:01:36",
        //         "version": 1
        //     })
        // }
        examDataCopy.activities.map((activity, index) => {
            parseText(activity);
            searchFormula(activity);
            searchOptionsFormula(activity);
            getTables(activity, index);
            return activity;
        });
        examDataCopy.name = _t("header.name");
        examDataCopy.surname = _t("header.surname");
        examDataCopy.date = _t("header.date");
        examDataCopy.course = _t("header.course");

        setDataFormated(examDataCopy);
    }

    const deleteActivity = async (index) => {
        if (window.confirm(_t("messages.confirm_remove_activity"))) {
            const activities = dataFormated.activities.filter((activity, i) => i !== index)
            setActivities(activities)
        }
    }

    const moveActivity = (type, index) => {
        switch (type) {
            case 'up':
                if (index > 0) {
                    const activities = [...dataFormated.activities]
                    const activity = activities[index]
                    activities[index] = activities[index - 1]
                    activities[index - 1] = activity
                    setActivities(activities)
                }
                break
            case 'down':
                if (index < dataFormated.activities.length - 1) {
                    const activities = [...dataFormated.activities]
                    const activity = activities[index]
                    activities[index] = activities[index + 1]
                    activities[index + 1] = activity
                    setActivities(activities)
                }
                break
        }
    }

    const changeActivity = async (id, index) => {
        let activitiesReload = [...activitiesToReload]
        const activities = [...dataFormated.activities]
        activitiesReload.push(id);
        setActivitiesToReload(activitiesReload)

        const newActivity = await getRandonActivity(isbn, units, language_default, oc_type, activities.map(e => e.id))
        if (newActivity !== null) {
            activities[index] = newActivity
        } else {
            alert(_t("messages.fail_no_activities"))
        }

        setActivities(activities)
        setActivitiesToReload(activitiesReload.filter(e => e !== id))
    }

    // const addActivity = async () => {
    //     const activities = [...dataFormated.activities]
    //     const newActivity = await getRandonActivity(isbn, units, language_default, activities.map(e => e.id))
    //     activities.push(newActivity)
    //     setActivities(activities)
    // }

    if (dataFormated === null) return null;

    return (
        <XboxExman>
            <h1 style={{ margin: '0px' }}>
                {_t("title")}
            </h1>

            <ExamContainer style={{ height:'height: calc(72vh - 100px)', overflow: 'hidden'}}>
                <ExamHeader>
                    <ExamTitle>{dataFormated?.title}</ExamTitle>
                </ExamHeader>
                <ExamContent>
                    {dataFormated.activities.map((activity, activityIndex) => {
                        if (activitiesToReload.includes(activity.id)) {
                            return <LoadContainer key={'activitiesToReload_' + activity.id}>
                                <XSpinner
                                    width="50px"
                                    border="10px"
                                />
                            </LoadContainer>
                        }

                        return <ActivityContainer key={'examPDF_' + activity.id} id={"activity_" + activity.id}>
                            <ActivityActionsButtons>
                                {dataFormated.activities.length > 1 &&
                                    <ContainerButtonsMove>
                                        <ButtonMove
                                            onClick={() => moveActivity("up", activityIndex)}
                                            style={{ marginTop: "0.5rem", }}
                                            disabled={activityIndex === 0}
                                        >
                                            <FontAwesomeIcon icon={faArrowCircleUp} size='1x' title={_t("tooltips.move_up")} />
                                        </ButtonMove>
                                        <ButtonMove
                                            onClick={() => moveActivity("down", activityIndex)}
                                            style={{ marginTop: "0.5rem", }}
                                            disabled={activityIndex === dataFormated.activities.length - 1}
                                        >
                                            <FontAwesomeIcon icon={faArrowCircleDown} size='1x' title={_t("tooltips.move_down")} />
                                        </ButtonMove>
                                    </ContainerButtonsMove>
                                }
                                <XButton
                                    onClick={() => changeActivity(activity.id, activityIndex)}
                                >
                                    <FontAwesomeIcon icon={faShuffle} size='1x' title={_t("tooltips.change_question")} />
                                </XButton>
                                <XButton
                                    onClick={() => deleteActivity(activityIndex)}
                                    color="danger"
                                    style={{ marginTop: "0.5rem", }}
                                >
                                    <FontAwesomeIcon icon={faTrashCan} size='1x' title={_t("tooltips.delete_question")} />
                                </XButton>

                            </ActivityActionsButtons>

                            <ActivityList key={'activity_' + activityIndex}>
                                <ActivityTitle>
                                    {activity?.warning && <FontAwesomeIcon icon={faCircleExclamation} size='1x' style={{ placeSelf: 'center', color: '#e51b23', paddingRight: '6px' }} title={activity.warning} />}
                                    {activityIndex + 1}) {activity?.title ? activity.title : `Activity #{${activity?.id}}`}
                                </ActivityTitle> 
                                {activity?.text_preview &&
                                    <ActivityContent dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(activity.text_preview) }} />
                                }
                                {!activity?.text_preview && !activity?.text.startsWith('<') &&
                                    <ActivityContent>{activity.text}</ActivityContent>
                                }
                                {activity?.formulasWithDetails?.map((formula, formulaIndex) => (
                                    <div style={{textAlign:'center'}} key={`image_${activity.id}_${formula?.src}`}>
                                        <ActivityImage 
                                            src={`${formula?.src.startsWith("@@@") ? XDAM : ''}${formula?.src}`} 
                                            alt={`activity_image_${formula?.src}`} 
                                            style={{ 
                                                height: formula?.height ?? 'auto',
                                                width: formula?.width ?? 'auto'
                                             }}
                                            onLoad={(e) => {
                                                setDataFormated(prevState => {
                                                    let activitiesFormated = [...prevState.activities]
                                                    activitiesFormated[activityIndex].formulasWithDetails[formulaIndex] = {
                                                        width: e.target.width,
                                                        height: e.target.height,
                                                        src: e.target.src
                                                    }
                                                    return {...prevState, activities: activitiesFormated}
                                                })  
                                            }}
                                        />
                                    </div>
                                ))}
                                {activity?.image &&
                                    <div style={{textAlign:'center'}}>
                                        <ActivityImage src={`${activity?.image.startsWith("@@@") ? XDAM : ''}${activity.image}`} alt='activity_image' />
                                    </div>
                                }
                                {ACTIVITIES_CLOSED_QUESTION.includes(activity.type) && activity?.options.map((option, optionIndex) => (
                                    <div key={`option_${activity.id}_${optionIndex}`} style={{ display: 'flex', flexWrap: 'wrap', flexDirection: option?.title_preview ? "column" : "row" }}>
                                        <div style={{ display: 'flex' }}>
                                            {showCorrectResults && 
                                                <CorrectionSymbol isRight={option.is_right}>
                                                    {option.is_right === 'TRUE' ? '✔ ' : '✘ '}
                                                </CorrectionSymbol> 
                                            }
                                            <ActivityListElement 
                                                key={'examPDF_option_' + activity.id + '_' + optionIndex} 
                                                marginLeft={showCorrectResults ? '1.5rem' : '3rem' }
                                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(option?.title_preview) }} 
                                            />
                                        </div>
                                        <span style={{ display: 'flex', flexWrap: 'wrap', flexDirection: "row" }}>
                                            {option?.image_url && 
                                                <OptionImage src={`${option.image_url.startsWith("@@@") ? XDAM : ''}${option.image_url}`} alt='option_image' />
                                            }
                                            {option?.formulasWithDetails?.map((formula, formulaIndex)=> (
                                                <OptionImage 
                                                    src={`${formula?.src?.startsWith("@@@") ? XDAM : ''}${formula?.src}`} 
                                                    key={`activity_option_image_${formula?.src}`}
                                                    alt={`activity_option_image_${formula?.src}`} 
                                                    style={{ marginLeft: (option?.title_preview && formulaIndex === 0) ? '3em' : '0' }}
                                                    onLoad={(e) => {
                                                        setDataFormated(prevState => {
                                                            let activitiesFormated = [...prevState.activities]
                                                            activitiesFormated[activityIndex].options[optionIndex].formulasWithDetails[formulaIndex] = {
                                                                width: e.target.width,
                                                                height: e.target.height,
                                                                src: e.target.src
                                                            }
                                                            return {...prevState, activities: activitiesFormated}
                                                        })                                             
                                                    }}
                                                />
                                            ))}
                                        </span>
                                    </div>
                                ))}
                                {ACTIVITIES_OPEN_QUESTION.includes(activity.type) && !showCorrectResults && (
                                    <div style={{ height: '4em' }}></div>
                                )}
                                {ACTIVITIES_OPEN_QUESTION.includes(activity.type) && showCorrectResults && (
                                    <div style={{ display: 'inline-flex', margin: '0.5rem 0 0 0', minHeight: 'calc(2em - 0.5rem)'}}>
                                        <CorrectionSymbol isRight={"TRUE"}>
                                            {'✔ '}
                                        </CorrectionSymbol> 
                                        <ActivityContent style={{ marginLeft: '0.5rem'}}>{activity.targets[0].accepted_values.join(', ')}</ActivityContent>
                                    </div>  
                                )}
                            </ActivityList>
                        </ActivityContainer>
                    })}
                </ExamContent>
                {/* <XButton onClick={() => addActivity()}>Add new</XButton> */}
            </ExamContainer>

            <ButtonsContainer>
                <SwitchContainer>
                    <StyledXLabel
                        label={_t("active_download_solutions")}
                        labelLocation="left"
                        component={
                            <XSwitch 
                                id="correct-results" 
                                color="secondary"
                                onChange={() => setShowCorrectResults(!showCorrectResults)} 
                            />
                        }
                        htmlFor="correct-results" 
                    />
                </SwitchContainer>
                <PDFDownloadLink
                    style={{ textDecoration: 'none', width: '-webkit-fill-available' }}
                    document={
                        <PdfExam
                            content={dataFormated}
                            tables={tables}
                            showCorrectResults={showCorrectResults}
                        />
                    }
                    fileName={dataFormated.title}
                >
                    <XButton
                        onClick={handleDownload}
                        style={{ width: '-webkit-fill-available', height: '-webkit-fill-available'}}
                    >
                        {_t("buttons.download_pdf")}                    
                    </XButton>
                </PDFDownloadLink>
                <XButton 
                    onClick={() => handleDownloadDOC(dataFormated, tables, showCorrectResults)}
                    style={{ width: '-webkit-fill-available' }}
                    disabled={isLoadingDOC}
                >
                    {isLoadingDOC ?
                        <FontAwesomeIcon icon={faSpinner} size='1x' spin style={{ placeSelf: 'center' }} title='loading' />
                        : error?.doc ?
                        < FontAwesomeIcon icon={faCircleExclamation} size='1x' style={{ placeSelf: 'center' }} title='error' />
                        : `${_t("buttons.download_doc")}` 
                    }
                </XButton>
            </ButtonsContainer>

        </XboxExman>

    )
}

export default Exam;
