import React from 'react'
import {
    Routes as RouterRoutes,
    Route,
    useLocation,
    useNavigate
} from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'

import { useTranslation } from 'react-i18next';
import { StyledXNav } from '../styled-components/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt, faRectangleList, faBook } from '@fortawesome/free-solid-svg-icons';

import Login from './../pages/Login/Login';
import Home from './../pages/Home/Home';
import ExamGeneratorCreator from './../pages/ExamGeneratorCreator/ExamGeneratorCreator';
import ExamView from '../pages/ExamView/ExamView';
import PageContainer from '../components/PageContainer/PageContainer';
import GeneratorView from '../pages/GeneratorView/GeneratorView';
import PageNotFound from '../pages/PageNotFound/PageNotFound';

const Routes = () => {
    const location = useLocation();
    const navigation = useNavigate();
    const [_t] = useTranslation("homePage");

    const pathname = location.pathname;
    
    const isUnprotectedRoute = pathname.split('/')[1] === 'generator' || pathname.split('/')[1] === 'exam-view';
    
    const isAuth = sessionStorage.getItem('isAuth') && sessionStorage.getItem('isAuth') === 'true';

    const logoLink = {
        logoImgSrc: '/images/logo-mhe.png',
        href: isUnprotectedRoute
            ? `/generator/${pathname.substring(location.pathname.lastIndexOf('/') + 1)}` 
            : isAuth 
            ? '/list-generators'
            : '/'
    };

    const links = isUnprotectedRoute || (location.pathname === '/') ? 
        [
            [
                {
                    text: _t("cover"),
                    url: `/generator/${location.pathname.substring(location.pathname.lastIndexOf('/') + 1)}` ,
                    target: '_self', 
                    icon: <FontAwesomeIcon icon={faBook} size="1x" /> 
                },
            ],
        ] : [
            [
                {
                    text: _t("title"),
                    url: '/list-generators',
                    target: '_self', 
                    icon: <FontAwesomeIcon icon={faRectangleList} size="1x" /> 
                },
            ],[
            {
                text: 'Superadmin',
                icon: <FontAwesomeIcon icon={faUser} size="1x" title="" />,
                items: [
                    {
                        text: 'Sign out',
                        icon: <FontAwesomeIcon icon={faSignOutAlt} size="1x" style={{ marginRight: '8px'}} />,
                        onClick: () => {
                            sessionStorage.clear();
                            navigation('/');
                        }
                    }
                ]
            },
        ]];

    return (
        <>
            <StyledXNav
                color='tertiary'
                logoLink={logoLink}
                links={links}
            />
            <PageContainer>
                <RouterRoutes>
                    <Route exact path='/' element={<Login />} />
                    <Route path='/list-generators' element={<ProtectedRoute><Home /></ProtectedRoute>} />
                    <Route path='/create-generator' element={<ProtectedRoute><ExamGeneratorCreator /></ProtectedRoute>} />
                    <Route path='/edit-generator/:isbnUnderscoreHash' element={<ProtectedRoute><ExamGeneratorCreator /></ProtectedRoute>} />
                    <Route path='/generator/:isbnUnderscoreHash' element={<GeneratorView />} />
                    <Route path='/exam-view/:isbnUnderscoreHash' element={<ExamView />} />
                    <Route path="*" element={<PageNotFound />} />
                </RouterRoutes>
            </PageContainer>
        </>
        
    )
}

export default Routes