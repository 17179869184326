import { createTheme } from '@mui/material';
import config from './config';

// export const ACTIVITIES_LIST = 'https://xeval.medu.ximdex.net/api/v1.0/exam-activities';
// export const EXAM_GENERATION = 'https://xeval.medu.ximdex.net/api/v1.0/exams';
// export const EXAM_GENERATOR = 'https://xeval.medu.ximdex.net/api/v1.0/exam-generators';
// export const SINGLE_EXAM_GENERATOR = 'https://xeval.medu.ximdex.net/api/v1.0/exam-generator'
// export const XDAM = 'http://xdam.medu.ximdex.net/api/v1/resource/render/';

export const ACTIVITIES_LIST = config.XEVAL_URI + '/exam-activities';
export const RANDON_ACTIVITY = config.XEVAL_URI + '/exams/{isbn}?activities={activities}&units={units}&lang={lang}&oc_type={oc_type}';
export const SINGLE_EXAM_GENERATOR = config.XEVAL_URI + '/exam-generator'
export const EXAM_GENERATION = config.XEVAL_URI + '/exams';
export const ACTIVITY = config.XEVAL_URI + '/activities/{id}';
export const EXAM_GENERATOR = config.XEVAL_URI + '/exam-generators';
export const UNIT_BY_ISBN_URI = config.XEVAL_URI + '/exam-generator/{isbn}/units?lang={lang}&oc_type={selectedActivityTypes}';

export const XDAM = process.env.REACT_APP_XEVAL_URI.includes('xeval2-backend.mhe') ? 'https://xdam.mhe.ximdex.net/api/v1/resource/render/' : 'https://xdam.medu.ximdex.net/api/v1/resource/render/';

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

export const ACTIVITIES_CLOSED_QUESTION = [
    'single-choice',
    'multiple-choice',
    'true-false'
];

export const ACTIVITIES_OPEN_QUESTION = [
    'input', // temporary until we have the name of the new activity type in xeval
    'input-long', // temporary until we have the name of the new activity type in xeval
];

export const LIGHT_THEME = createTheme(
    {
        palette: {
            mode: 'light',
            primary: {
                main: '#e51b23',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#06235B',
                contrastText: '#ffffff',
            },
            tertiary: {
                main: '#ffffff',
                contrastText: '#e51b23',
            },
            danger: {
                main: '#e51b23',
                dark: "#D20303",
                contrastText: '#ffffff',
            },
            background: {
                100: '#ffffff',  // same as paper
                200: '#f5f5f5',
                300: '#f9fbfc',  // same as primary
                400: '#eaeaea',
                500: '#e0e0e0',  // same as secondary
                600: '#adadad',  // same as tertiary
                700: '#686868',
                primary: '#f9fbfc',
                secondary: '#e0e0e0',
                tertiary: '#adadad',
                paper: "#ffffff",
                default: "#eee",
                transparent: "transparent",
            },
            status: {
                error: '#e13144',
                warning: '#f6ab0e',
                success: '#4ba0a0',
                info: '#5c9ad0',
                correct: {
                    100: '#e9f5ed',
                    200: '#95cda9'
                },
                incorrect: {
                    100: '#f5e9ed',
                    200: '#cd95a9'
                }
            },
            font: {
                primary: '#222',
            }
        },
        size: {
            em: {
                xxs: '0.4em',
                xs: '0.6em',
                s: '0.8em',
                m: '1em',
                l: '1.25em',
                xl: '1.5em',
                xxl: '2em',
                unset: 'unset'
            },
            px: {
                xxs: '6px',
                xs: '9px',
                s: '12px',
                m: '16px',
                l: '20px',
                xl: '24px',
                xxl: '32px',
                unset: 'unset'
            },
            navbar: {
                s: '55px',
                m: '82px',
                unset: 'unset',
            },
        },
        properties: {
            padding: {
                xs: '4px',
                s: '8px',
                m: '16px',
                l: '32px',
                unset: 'unset',
            },
            paddingX: {
                s: '0px 8px',
                m: '0px 16px',
                l: '0px 32px',
                unset: 'unset',
            },
            paddingY: {
                s: '8px 0px',
                m: '16px 0px',
                l: '32px 0px',
                unset: 'unset',
            },
            radius: {
                m: '8px',
            },
            weight: {
                xs: '200',
                s: '300',
                m: '400',
                l: '500',
                xl: '600',
                xxl: '800'
            },
            border: {
                m: '1px solid #adadad',
                l: '4px solid #adadad',
                status: {
                    active: '4px solid #e51b23',
                    inactive: '4px solid #adadad',
                }
            }
        }
    }
);

export const LANGUAGES = [
    { title: 'Español', id: 'ES' },
    { title: 'Català', id: 'CA' },
    { title: 'English', id: 'EN' },
];

export const LEGAL_NOTICES = {
    CA: `Drets reservats © 20XX, respecte a la primera edició en espanyol, per McGraw-Hill/Interamericana de España, S.L.
    
No està permesa la reproducció total o parcial d'aquesta eina, ni el seu tractament informàtic, ni la transmissió de cap manera o per qualsevol mitjà, ja sigui electrònic, mecànic, per impressió, per enregistrament o altres mètodes, sense el permís previ i per escrit dels titulars del Copyright.`,
    EN: `All rights reserved © 20XX, regarding the first edition in Spanish, by McGraw-Hill/Interamericana de España, S.L.
    
No part of this tool may be reproduced, computer processed, or transmitted in any form or by any means, whether electronic, mechanical, by printing, by recording or other methods, without the prior permission in writing of the copyright holders.`,
    ES: `Derechos reservados © 20XX, respecto a la primera edición en español, por McGraw-Hill/Interamericana de España, S.L.
    
No está permitida la reproducción total o parcial de esta herramienta, ni su tratamiento informático, ni la transmisión de ninguna forma o por cualquier medio, ya sea electrónico, mecánico, por impresión, por grabación u otros métodos, sin el permiso previo y por escrito de los titulares del Copyright.`,
}
