import styled from 'styled-components/macro'
import { XDropdown } from '@ximdex/xui-react/material'

export const StyledXDropdown = styled(XDropdown)`
    // fix necessary for deployment 
    .css-17zbbii {
        margin: 16px 0px 8px;
    }

    .css-1n5ilea-MuiFormControl-root-MuiTextField-root .MuiAutocomplete-tag {
        // mhe red
        background-color: rgba(229,27,35, 1);
        border: 1px solid rgb(229,27,35);

        // mhe dark blue
        // background-color: rgba(10,34,61, 0.75);
        // border: 1px solid rgb(10,34,61);
    } 

    .MuiChip-deleteIcon {
        color: #ffffff !important;
        opacity: 1 !important;
    }

    .css-wjsjww-MuiChip-label {
        color: #ffffff !important;
    }
`