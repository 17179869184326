import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import { useSpinner } from '@ximdex/xui-react/hooks';
import { XButton } from '@ximdex/xui-react/material';
import { CoverContainer } from './StyledGeneratorView';
import ActivitiesList from '../../components/ActivitiesList/ActivitiesList';
import ExamForm from '../../components/ExamForm/ExamForm';
import { fetchExamGeneratorByIsbn, getUnitsByIsbn, getActivitiesByIsbnAndUnits } from '../../services/exam.service';

function useBook() {
    const { isbnUnderscoreHash } = useParams();
    const { showSpinner, hideSpinner } = useSpinner();
    const [booksState, setBook] = useState(null);
    const [_t] = useTranslation("generatorView");

    useEffect(() => {
        showSpinner();

        fetchExamGeneratorByIsbn(isbnUnderscoreHash)
            .then(generator => ({
                ...generator,
                isbn: generator.isbn,
                hash: generator.hash,
            }))
            .then(setBook)
            .catch(() => {
                alert(_t("messages.fail_fetch_generator"))
                setBook(undefined)
            })
            .finally(() => hideSpinner())
    }, []);

    return { book: booksState };
}

const GeneratorView = () => {
    const { book } = useBook();
    const initActivitiesState = { 
        isLoading: false, 
        total: 0 
    };
    const [activities, setActivities] = useState(initActivitiesState); 
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [selectedActivityTypes, setSelectedActivityTypes] = useState('all');
    const [generatedExam, setGeneratedExam] = useState({});
    const [showGeneratorFrontPage, setShowGeneratorFrontPage] = useState(true);
    const [_t] = useTranslation("generatorView");
    const { showSpinner, hideSpinner } = useSpinner();

    const [units, setUnits] = useState(undefined);
    const [loadActivities, setLoadActivities] = useState(true);

    let isbn = book?.isbn;
    let language_default = book?.language_default;

    useEffect(() => {
        const init = async () => {
            if (book?.language_default) {
                const bookLang = book.language_default.slice(0, 2).toLowerCase()
                i18next.changeLanguage(bookLang)
                sessionStorage.setItem("currentLang", bookLang)
            }

            if (book?.isbn) {
                const units = await getUnitsByIsbn(book, selectedActivityTypes)
                setUnits(units)
            }

            setActivities(initActivitiesState)
        }
        if (book) init()
    }, [book, selectedActivityTypes]);

    useEffect(() => {
        selectedUnits?.length > 0 ? getAllActivities() : setActivities(initActivitiesState); 
    }, [activities?.current_page, loadActivities]);

    const getAllActivities = async () => {
        showSpinner();
        
        try {
            const newActivities = await getActivitiesByIsbnAndUnits(isbn, selectedUnits.map(e => e.id), selectedActivityTypes, language_default, activities?.current_page)
            const activitiesList = newActivities.data.map(activity => {
                const modifiedActivity = {
                    id: activity.id,
                    title: activity.title,
                    text: activity.text,
                    type: activity.type
                };
                return modifiedActivity;
            })
            setActivities({...newActivities, isLoading: false, data: activitiesList});
        } catch (error) {
            alert(_t("messages.fail_fetch_activities"))
            console.error("Error getting activities", error);
        }

        hideSpinner();
    }


    if (book === null) return null;
    if (book === undefined) return <h2>Book not found</h2>
    
    return (
        <div style={{ height: 'calc(100vh - (82px + 30px))', overflowY: 'overlay' }}>
            {showGeneratorFrontPage ? 
                <CoverContainer>
                    <h2 style={{ marginTop: '0' }}>
                        {book.title}
                    </h2>
                    <img 
                        src={book?.image ? book.image : '/images/logo-mhe-2.png'} 
                        alt='portada del generador de exámenes'
                        onError={(e) => { e.target.onError = null; e.target.src = '/images/logo-mhe-2.png' }}
                    />
                    <br />
                    <br />
                    <XButton onClick={() => setShowGeneratorFrontPage(false)}>
                        {_t("buttons.enter_generator")}
                    </XButton>
                </CoverContainer> 
                : 
                <>
                    <h2>{book.title}</h2>
                    <ExamForm
                        isbn={book.isbn}
                        hash={book.hash}
                        language_default={book?.language_default}
                        units={units}
                        setSelectedUnits={setSelectedUnits}
                        totalActivities={activities?.total ?? 0}
                        generatedExam={generatedExam}
                        setGeneratedExam={setGeneratedExam}
                        selectedUnits={selectedUnits}
                        setLoadActivities={setLoadActivities}
                        setShowGeneratorFrontPage={setShowGeneratorFrontPage}
                        setSelectedActivityTypes={setSelectedActivityTypes}
                    />
                    <ActivitiesList
                        activities={activities}
                        setActivities={setActivities}
                    />
                </>
            }
        </div>
    )
}

export default GeneratorView;
