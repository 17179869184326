import { styled } from '@mui/system';
const PageContainer = styled('div')`
    margin: 0 auto;

    @media only screen and (min-width: 900px ) and (max-width: 1200px ){
        max-width: 900px;
    }

    @media only screen and (min-width: 1200px ) and (max-width: 2000px ){
        max-width:1100px;
    }
    
    @media only screen and (min-width: 2000px ){
        max-width:1400px;
    }
`

export default PageContainer