import React from 'react';
import {
    Document,
    Page,
    View,
    Text,
    StyleSheet,
    Font,
    Image
} from '@react-pdf/renderer';
import { XDAM, ACTIVITIES_OPEN_QUESTION } from './../../CONSTANTS';

const PdfExam = (props) => {
    const examData = props?.content;
    const parse = require('html-react-parser');
    const logoMHE = '/images/logo-mhe.png'

    Font.register({ family: 'Verdana', src: `${process.env.PUBLIC_URL}/fonts/Verdana.ttf`, fontStyle: 'normal', fontWeight: 'normal' });
    Font.register({ family: 'Verdana Bold', src: `${process.env.PUBLIC_URL}/fonts/VerdanaBold.ttf`, fontStyle: 'normal', fontWeight: 'bold' });
    Font.register({ family: 'Symbola', src: `${process.env.PUBLIC_URL}/fonts/Symbola.ttf` });

    if (!examData) return null;

    const parseData = (text) => {
        const isStringHTML = /<\/?[a-z][\s\S]*>/i.test(text)
        let content = text

        if (isStringHTML) {
            let element = document.createElement('div')
            element.innerHTML = text
            content = element?.textContent ?? element?.innerText
        }

        return content
    }

    const createTables = (tables, index) => {
        const rows = [];
        if (tables.length > 0) {
            const convertedTable = [...tables];
            convertedTable.forEach(table => {
                const activityIndex = table.getAttribute('data-activity');
                if (activityIndex === index) {
                    [...table.children].forEach(body => {
                        [...body.children].forEach((row, rowIndex) => {
                            rows.push([]);
                            [...row.children].forEach(cell => {
                                rows[rowIndex].push(cell.innerHTML)

                            })
                        })
                    })
                }
            })
        }
        if (rows.length > 0) {
            return (
                <View style={styles.table}>
                    {rows.map((row, index) => (
                        <View key={'row' + index} style={styles.tableRow}>
                            {getRowsContent(row)}
                        </View>
                    ))}
                </View >
            )
        } else return null;
    }

    const getRowsContent = (row) => {
        const width = 100 / (row.length);
        return row?.map((cell, index) => (
            <View key={'cell' + index} style={[styles.tableCol, { width: width + '%' }]}>
                <Text style={styles.tableCell}>{cell}</Text>
            </View>
        ))
    }

    return (
        <Document>
            <Page style={styles.body}>
                <Image alt='logo-mhe' src={logoMHE} style={styles.logoMhe} fixed />
                {examData?.title &&
                    <Text style={styles.header} fixed>
                        {examData?.title}
                    </Text>
                }
                <View style={styles.table_header}>
                        <View style={[styles.row_header]}>
                            <Text style={[styles.cell_header]}>{examData.name}</Text>
                            <Text style={[styles.cell_header]}>{examData.surname}</Text>
                        </View>
                        <View style={[styles.row_header]}>
                            <Text style={[styles.cell_header]}>{examData.date}</Text>
                            <Text style={[styles.cell_header]}>{examData.course}</Text>
                        </View>
                </View>
                {examData?.activities?.map((activity, index) => {
                    return (
                        <View key={'examData_activity' + index}>
                            {activity?.title && <Text style={styles.question}>{index + 1}) {parse(activity?.title)}</Text>}
                            {activity?.text && <Text style={styles.text}>{parseData(activity?.text)}</Text>}
                            {activity?.formulasWithDetails?.map((formula, index) => (
                                <Image
                                    key={'formula_' + activity.id + '_' + formula?.src}
                                    alt={'formula_' + activity.id + '_' + formula?.src}
                                    src={{
                                        uri: `${formula?.src?.startsWith('@@@') ? XDAM : ''}${formula?.src}`,
                                        method: 'GET',
                                        allowedOrigin: '*',
                                        accessControlAllowOrigin: '*',
                                        headers: {
                                            // 'Access-Control-Allow-Origin': '*', 
                                            // 'Access-Control-Allow-Credentials': 'true', 
                                            'Cache-Control': 'no-cache',
                                            'mode': 'no-cors',
                                        },
                                        body: ''
                                    }}
                                    style={[styles.formula, { width: formula?.width + 'px', height: formula?.height + 'px' }]}
                                />
                            ))}
                            {createTables(props.tables, index)}
                            {activity?.image &&
                                < Image
                                    alt='activity_image'
                                    src={{
                                        uri: `${activity?.image?.startsWith('@@@') ? XDAM : ''}${activity?.image}`,
                                        method: 'GET',
                                        headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': 'true', 'Cache-Control': 'no-cache', mode: 'no-cors' },
                                        body: ''
                                    }}
                                    style={styles.image}
                                />
                            }
                            {ACTIVITIES_OPEN_QUESTION.includes(activity.type) ?
                                <View key={'open_answer_' + activity.id} style={{ minHeight: '50px' }}>
                                    {props.showCorrectResults &&
                                        <Text style={[styles.answer, { marginTop: 6 }]}>
                                            <Text style={[styles.symbol, { color: 'green' }]}>{'✔ '}</Text>
                                            {parseData(activity.targets[0].accepted_values.join(', '))}
                                        </Text>
                                    }
                                </View>
                                : 
                                activity?.options?.map((option, indexOption) => (
                                    <View key={'options' + indexOption} style={[styles.options, { flexDirection: option?.title_preview ? 'column' : 'row' }]}>
                                        {option?.title &&
                                            <Text style={styles.answer}>
                                                {props.showCorrectResults && <Text style={option?.is_right?.toUpperCase() === 'TRUE' ? [styles.symbol, { color: 'green' }] : [styles.symbol, { color: 'red' }]}>{option?.is_right?.toUpperCase() === 'TRUE' ? '✔ ' : '✘ '}</Text>}
                                                {(indexOption + 10).toString(36)}. {parseData(option?.title)}
                                            </Text>
                                        }
                                        <View style={{ display: 'flex', flexWrap: 'wrap', flexDirection: "row", marginLeft: option?.title_preview ? 24 : 0 }}>
                                            {option?.image_url &&
                                                <Image
                                                    key={'option_image_formula' + indexOption}
                                                    alt={'option_image' + indexOption}
                                                    src={{
                                                        uri: `${activity?.image_url?.startsWith('@@@') ? XDAM : ''}${option?.image_url}`,
                                                        method: 'GET',
                                                        headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': 'true', 'Cache-Control': 'no-cache', mode: 'no-cors' },
                                                        body: ''
                                                    }}
                                                    style={styles.optionImage}
                                                />
                                            }
                                            {option?.formulasWithDetails?.map((formula, indexFormula) => (
                                                <Image
                                                    key={'option_formula_' + activity.id + '_' + formula?.src}
                                                    alt={'option_formula_' + activity.id + '_' + formula?.src}
                                                    src={{
                                                        uri: `${formula?.src?.startsWith('@@@') ? XDAM : ''}${formula?.src}`,
                                                        method: 'GET',
                                                        headers: { 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': 'true', 'Cache-Control': 'no-cache', mode: 'no-cors' },
                                                        body: ''
                                                    }}
                                                    style={[styles.optionImage, { width: formula?.width + 'px', height: formula?.height + 'px' }]}
                                                />
                                            ))}
                                        </View>
                                    </View>
                                ))
                            }
                        </View>
                    )
                })}
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber}-${totalPages}`
                )} fixed />
            </Page>
        </Document>
    )
}


Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 46,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontWeight: 'bold',
        fontFamily: 'Verdana',
    },
    title: {
        fontSize: 14,
        textAlign: 'center',
        fontFamily: 'Verdana Bold',
    },
    question: {
        fontSize: 12,
        marginTop: 18,
        fontFamily: 'Verdana Bold',
    },
    options: {
        marginTop: 6, 
        display: "flex",
        flexWrap: "wrap"
    },
    answer: {
        fontSize: 12,
        marginLeft: 12,
    },
    symbol: {
        fontFamily: 'Symbola'
    },
    correct: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#95cda9'
    },
    text: {
        marginTop: 3,
        fontSize: 12,
        textAlign: 'justify',
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
        width: 100,
        height: 100
    },
    formula: {
        maxHeight: '200px',
        margin: '0 auto'
    },
    optionImage: {
        maxHeight: '200px',
        margin: '0 auto 0 0'
    },
    header: {
        fontSize: 16,
        marginBottom: 20,
        marginLeft: 55,
        paddingBottom: 4,
        textAlign: 'right',
        color: 'grey',
        borderBottom: '1px solid black',
    },
    logoMhe: {
        position: 'absolute',
        top: 20,
        left: 35,
        width: 50,
        height: 50
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 11,
        bottom: 30,
        left: 0,
        right: 35,
        textAlign: 'right',
        color: 'grey',
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row'
    },
    tableCol: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: 'auto',
        marginTop: 5,
        fontSize: 10
    },
    table_header: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Verdana',
        fontSize: 12
    },
    row_header: {
        display: "flex",
        flexDirection: "row",
        flexBasis: 22
    },
    cell_header: {
        flexGrow: 1,
        flexBasis: "auto"
    }
});

export default PdfExam;
