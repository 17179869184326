import { styled } from '@mui/system'
import { XButton } from '@ximdex/xui-react/material'

export const FormStyled = styled('form')`
    width: 75%;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    background-color: #fff;
    padding: 16px 24px 24px 24px;
    border-radius: 4px;
    position: relative;

    & .css-1m8lgbg-MuiAutocomplete-root {
        margin-top: 16px;
        margin-bottom: 8px;
    }

    & .css-1n5ilea-MuiFormControl-root-MuiTextField-root {
        margin-top: 16px;
        margin-bottom: 8px;
    }
`

export const StyledXButton = styled(XButton)`
    width: calc(75% + (24px + 24px) );
    min-width: calc(400px + (24px + 24px));
`