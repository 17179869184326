import { styled } from '@mui/system';
import { XCard } from '@ximdex/xui-react/material'

export const StyledXCard = styled(XCard)`
    width: 75%;
    min-width: 400px;
    margin: 1rem auto;
    padding: 8px 24px;
    border-radius: 4px;

    // header
    .css-1pfy6pg {
        color: #06235b;
        font-weight: bold;
    }

    // button
    .css-1477lev {
        background-color: unset;
        width: 30px;
        height: 30px;
        
        & svg {
            fill: black;
            width: 10px;   
        }
    }
`

export const ActivityContainer = styled('div')`
    border: 1px solid #ecf1f6;
    border-radius: 8px;
    background-color: #fcfdfe;
    margin: 8px 0;
    padding: 0 24px;
    text-align: left;
    color: #06235b;
`
export const IdParagraph = styled('p')`
    font-weight: bold;
`

export const TitleParagraph = styled('p')`
    margin: 8px 0 0 0;
`

export const TextParagraph = styled(TitleParagraph)`
    display: -webkit-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`