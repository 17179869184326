import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faDesktop, faLink, faPenToSquare, faClone } from '@fortawesome/free-solid-svg-icons';
import { XButton, XList } from '@ximdex/xui-react/material';
import {
    StyledHeaderList,
    MainTitle,
    GeneratorLink,
    StyledDivList
} from './StyledHome';
import { fetchExamGenerators, deleteGenerator } from '../../services/exam.service';
import { useSpinner } from '@ximdex/xui-react/hooks';
import { useTranslation } from 'react-i18next';
import MessageToUser from '../../components/MessageToUser/MessageToUser';
import { LEGAL_NOTICES } from '../../CONSTANTS';

const Home = () => {
    const [finalList, setFinalList] = useState([]);
    const [generators, setGenerators] = useState([]);
    const { showSpinner, hideSpinner } = useSpinner();
    const [messageToUser, setMessageToUser] = useState('')
    const [_t] = useTranslation("homePage")

    useEffect(() => {
        addControls();
    }, [generators]);

    useEffect(() => {
        sessionStorage.removeItem("currentLang")
        getGenerators()
    }, []);

    const getGenerators = () => {
        showSpinner()
        fetchExamGenerators().then(data => {
            const modifiedData = data.map(generator => {
                if (!generator.hash) throw new Error();
                
                return generator
            });
            setGenerators(modifiedData);
        }).catch(error => {
            console.error(error)
            alert(_t("messages.fail_fetch_generators"));
        }).finally(() => {
            hideSpinner();
        })
    }

    const confirmDeleteGenerator = (id, titleOfBook) => {
        if (window.confirm(_t("messages.confirm_delete_generator").replace("{title}", titleOfBook))) {
            deleteGenerator(id).then(res => {
                if (res.status === 200 || res.status === 202) {
                    getGenerators()
                    setMessageToUser(_t("messages.success_delete_generator"))
                } else (
                    setMessageToUser(_t("messages.fail_delete_generator"))
                )
            }).catch(err => {
                console.error(err) 
                setMessageToUser(_t("messages.fail_delete_generator"))
            })
        }
    }

    const copyGeneratorLinkToClipboard = (book) => {
        const generatorLink =  window.location.origin + '/generator/' + book.isbn + '_' + book.hash
        setMessageToUser(`${generatorLink} copied to clipboard.`)
        navigator.clipboard.writeText(generatorLink) 
    }

    const addControls = async () => {
        const listWithControls = generators.map(book => (
            {
                isbn: book.isbn,
                subject: book.title,
                controls:
                    <>
                        <GeneratorLink
                            to={`/generator/${book.isbn}_${book.hash}`}
                            state={{ book: book }}
                            target="_blank" rel="noopener noreferrer"
                        >
                            <XButton>
                                <FontAwesomeIcon icon={faDesktop} size='1x' title={_t("tooltips.enter_generator")} />
                            </XButton>
                        </GeneratorLink>
                        <XButton onClick={() => copyGeneratorLinkToClipboard(book)}>
                            <FontAwesomeIcon icon={faLink} size='1x' title={_t("tooltips.copy_link")} />
                        </XButton>
                        <GeneratorLink
                            to={`/create-generator`}
                            state={{ 
                                book: {
                                    title: book?.title || '',
                                    subject: book?.subject || '',
                                    isbn: '',
                                    language_default: book?.language_default || 'ES',
                                    level: 1,
                                    url: 'https://www.ximdex.com/',
                                    demo_url: 'https://www.ximdex.com/',
                                    credits: book?.credits || '', 
                                    legal_notice: LEGAL_NOTICES[book?.language_default || 'ES'],
                                    version: 1,
                                    image: book?.image, 
                                }, 
                                clone: true 
                            }}
                        >
                            <XButton>
                                <FontAwesomeIcon icon={faClone} size='1x' title='Clone generator'/>
                            </XButton>
                        </GeneratorLink>
                        <GeneratorLink
                            to={`/edit-generator/${book.isbn}_${book.hash}`}
                            state={{ book: book, edit: true }}
                        >
                            <XButton>
                                <FontAwesomeIcon icon={faPenToSquare} size='1x' title={_t("tooltips.edit_generator")} />
                            </XButton>
                        </GeneratorLink>
                        <XButton onClick={() => confirmDeleteGenerator(book.id, book.title)}>
                            <FontAwesomeIcon icon={faTrashCan} size='1x' title={_t("tooltips.delete_generator")} />
                        </XButton>
                    </>
            }
        ));

        // removed for now
        // <XButton disabled>
        //     <FontAwesomeIcon icon={faClone} size='1x' title={_t("tooltips.duplicate_generator")} />
        // </XButton>

        setFinalList(listWithControls);
    };

    return (
        <>
            <StyledHeaderList>
                <MainTitle>{_t("title")}</MainTitle>
                <GeneratorLink to={'/create-generator'} state={{ edit: false }}>
                    <XButton>
                        <FontAwesomeIcon icon={faPlusSquare} size='1x' style={{ marginRight: "6px" }} />{_t("add_new_generator")}
                    </XButton>
                </GeneratorLink>
            </StyledHeaderList>

            {finalList.length > 0 &&
                <StyledDivList>
                    <XList 
                        list={finalList} 
                        initRowsPerPage={25} 
                        rowsPerPageOptionsAvailable = {[25, { label: 'All', value: -1 }]}
                    />
                </StyledDivList>
            }

            {messageToUser &&
                <MessageToUser 
                    messageToUser={messageToUser} 
                    setMessageToUser={setMessageToUser}
                />
            }
        </>
    )
}

export default Home;
