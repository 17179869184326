import { styled } from '@mui/system';

export const CoverContainer = styled('div')`
    margin: 8px auto 28px auto;
    padding: 2.5em;
    background-color: #fff;
    border-radius: 8px;
    width: fit-content;

    img {
        max-height: 55vh;
    }
`