import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

export const MainTitle = styled('h1')`
    font-size: 2rem;
    font-weight: 500;
    margin: 1rem 0;
`;

export const List = styled('ul')`
    list-style: none;
    text-align: left;
`;

export const ListElement = styled('li')`
    margin-bottom: 1rem;
`;

export const GeneratorLink = styled(Link)`
    text-decoration: none;
`;

export const StyledDivList = styled('div')`
    padding: 16px;

    & td:nth-of-type(3n) {
        display: flex;
        justify-content: flex-end;

        & button {
            margin-left: 8px;
        }
    }
`

export const StyledHeaderList = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
`
