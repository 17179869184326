import { styled } from '@mui/system'
import { XButton } from '@ximdex/xui-react/material'

export const StyledForm = styled('form')`
    background-color: #fff;
    box-shadow: 0px 1px 2px -1px rgb(249 251 252 / 10%), 0px 2px 5px 0px rgb(249 251 252 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    border-radius: 8px;
    width: 50%;
    margin: 15vh auto 0 auto;
    padding: 2em 2em 3em 2em;
`

export const StyledSubmitButton = styled(XButton)`
    margin: 1em 0 8px 0;
`