import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSpinner } from '@ximdex/xui-react/hooks';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faSpinner, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { XInput } from '@ximdex/xui-react/material';
import { FormStyled, StyledXButton } from './StyledExamForm';
import { StyledDivSideBySide, StyledXDropdown, StyledHelperText } from '../../styled-components/index'
import { EXAM_GENERATION } from '../../CONSTANTS';
import MessageToUser from '../MessageToUser/MessageToUser';

const ExamForm = ({
    isbn,
    hash,
    language_default,
    setSelectedUnits,
    totalActivities,
    generatedExam,
    setGeneratedExam,
    units,
    selectedUnits,
    getAllActivities,
    setLoadActivities,
    setShowGeneratorFrontPage,
    setSelectedActivityTypes,
    ...props
}) => {
    const { showSpinner, hideSpinner } = useSpinner();
    const [_t] = useTranslation("generatorView");
    const navigate = useNavigate();

    const activityTypes = [
        {label: _t("dropdown.options.all_types"), value: 'all'},
        {label: _t("dropdown.options.open"), value: 'open'},
        {label: _t("dropdown.options.closed"), value: 'closed'}
    ];

    const initExamInfo = {
        'title': '',
        'num_questions': '',
        'units': [], 
        'activity_types': activityTypes[0].value
    };

    const [examInfo, setExamInfo] = useState(initExamInfo);
    const [messageToUser, setMessageToUser] = useState('');
    const [isBlockedLoadActivities, setIsBlockedLoadActivities] = useState(false);

    const returnToCoverPage = () => {
        setGeneratedExam({});
        setShowGeneratorFrontPage(true);
    };

    const saveExamInfo = (value, field, e) => {
        const exam = { ...examInfo };

        if (field === 'num_questions' && parseInt(value) > totalActivities) {
            setMessageToUser(_t("messages.fail_num_questions").replace("{value}", value))
        } else if (field === 'units') {
            const modifiedUnits = value.map(unit => {
                return unit = unit.id
            });
            exam[field] = modifiedUnits.join(',');
        } else if (field === 'activity_types') {
            exam[field] = value.value;
            exam['num_questions'] = initExamInfo.num_questions;
            exam['units'] = initExamInfo.units;
            setSelectedActivityTypes(value.value);
            setSelectedUnits([]);
        } else {
            exam[field] = value;
        };

        setExamInfo(exam);

        if (field === 'units') {
            setSelectedUnits(value);
            if (!isBlockedLoadActivities) setLoadActivities(prevState => !prevState);
        };
    };

    const handleUnitsOnClose = () => {
        setIsBlockedLoadActivities(false);
        setLoadActivities(prevState => !prevState);
    };

    return (
        <div>
            {!generatedExam.title &&
                <>
                    <FormStyled>
                        <XInput
                            label={_t('inputs.exam_title')}
                            name={_t('inputs.exam_title')}
                            autoComplete='true'
                            type="text"
                            value={examInfo?.title}
                            required
                            onChange={(e) => saveExamInfo((e.target.value), 'title')}
                        />
                        <StyledXDropdown
                            label={_t("dropdown.label")}
                            size='small'
                            value={activityTypes.filter(type => type.value === examInfo.activity_types)[0]}
                            options={activityTypes}
                            labelOptions='label'
                            renderOption={(opt) => opt.label}
                            multiple={false}
                            disableClearable={true}
                            hasCheckboxes={false}
                            onChange={(e, value) => saveExamInfo(value, 'activity_types')}
                        />
                        <StyledDivSideBySide>
                            {units ?
                                <>
                                <StyledXDropdown
                                    label={_t('inputs.units')}
                                    options={units}
                                    value={selectedUnits}
                                    labelOptions='id'
                                    required
                                    multiple
                                    size='small'
                                    renderOption={(opt) => `${(+opt.id) <= 9 ? "0" + +opt.id : opt.id} (${opt.activities} ${_t('inputs.questions')})`}
                                    disableCloseOnSelect
                                    onChange={(e, value) => saveExamInfo(value, 'units')}
                                    onOpen={() => setIsBlockedLoadActivities(true)}
                                    onClose={() => handleUnitsOnClose()}
                                    />
                                {units.length === 0 &&
                                    <StyledHelperText type='info' style={{ position: 'absolute', bottom: '10px', left: '10px' }}>
                                        {_t('helper_texts.instructions_not_units')} 
                                    </StyledHelperText>
                                }
                                </>
                                :
                                <FontAwesomeIcon icon={faSpinner} size='1x' spin style={{placeSelf: 'center'}} title='loading units' />
                            }

                            <div style={{ position: 'relative' }}>
                                <XInput
                                    label={_t('inputs.num_questions')}
                                    value={examInfo?.num_questions}
                                    required
                                    type='number'
                                    size='small'
                                    style={{ width: '100%' }}
                                    disabled={!totalActivities}
                                    autoComplete='false'
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: {totalActivities} }}
                                    onChange={(e) => saveExamInfo(e.target.value, 'num_questions', e)}
                                />
                                
                                <StyledHelperText type='info' style={{ position: 'absolute', bottom: '-15px', left: '-10px' }}>
                                    {totalActivities ? 
                                        _t('helper_texts.max') + ': ' + totalActivities : 
                                        _t('helper_texts.instructions_num_questions')
                                    } 
                                </StyledHelperText>
                            </div>
                            
                        </StyledDivSideBySide>
                    </FormStyled>
                    <StyledXButton
                        onClick={() => navigate(`/exam-view/${isbn}_${hash}?lang=${language_default}&oc_type=${examInfo?.activity_types || 'all'}&units=${examInfo.units}&title=${examInfo.title.trim()}&num=${examInfo.num_questions}`)}
                        disabled={!examInfo.title || !examInfo.num_questions || !examInfo.units.length}
                    >
                        <FontAwesomeIcon icon={faDesktop} size='1x' style={{ marginRight: "6px" }} />{_t("buttons.generate_exam")}
                    </StyledXButton>
                    <StyledXButton
                        variant="outlined"
                        style={{margin: '8px 0 0 0'}}
                        onClick={returnToCoverPage}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} size='1x' style={{ marginRight: "6px" }}/>{_t("buttons.go_back_to_cover")}
                    </StyledXButton>
                </>
            }
            {messageToUser &&
                <MessageToUser 
                    messageToUser={messageToUser} 
                    setMessageToUser={setMessageToUser}
                />
            }
        </div>
    )
}

export default ExamForm;
