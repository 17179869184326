import './App.css';
import { LIGHT_THEME } from './CONSTANTS';
import { XThemeProvider } from '@ximdex/xui-react/providers';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/Routes';

const App = () => {

    return (
        <BrowserRouter>
            <div className="App">
                <XThemeProvider lightTheme={LIGHT_THEME}>
                    <Routes />
                </XThemeProvider>
            </div>
        </BrowserRouter>
    );
}

export default App;
