import { styled } from '@mui/system';
import { XBox, XButton, XLabel } from '@ximdex/xui-react/material';

export const MainTitle = styled('h3')`
    font-size: 2rem;
    font-weight: 200;
    margin: 1rem 0;
`;

export const XboxExman = styled(XBox)`
    margin: 2rem auto;
    padding: unset;
    max-width: 850px;
    background-color: unset;
    border: unset; 

    @media only screen and (max-width: 900px ){
        margin-left: 1rem;
        margin-right: 1rem;
    }
`;

export const ExamHeader = styled('div')`
    background: #dedede;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0 0;
`;

export const ExamContent = styled('div')`
    height: calc(100vh - 82px - 30px - 2em - 76px - 75px - 32px - 1.5em);
    overflow-y: auto;
`

export const ExamContainer = styled('div')`
    // width: 80%;
    margin: 1rem auto;
    text-align: left;
    border-radius: 8px;
    box-shadow: 0px 1px 2px -1px rgb(249 251 252 / 10%), 0px 2px 5px 0px rgb(249 251 252 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
`;

export const ActivityActionsButtons = styled(({ className, ...props }) => <div className={'action-buttons ' + className}{...props}></div>)`
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    flex-direction: column;
`

export const ActivityContainer = styled('div')`
    margin: 1rem 2rem;
    border: 1px solid transparent;
    position: relative;
    cursor: pointer;
    font-family: Verdana, Arial, sans-serif !important;

    &:hover {
        border: 1px solid #d0d0d0;
        border-radius: 10px;

        & .action-buttons{
            display: flex;
        }
    }
`;

// export const ActivityContainer = styled('div')`
//     margin: 2rem;
// `;

export const LoadContainer = styled(ActivityContainer)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ExamTitle = styled('p')`
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
`;

export const ActivityTitle = styled('span')`
    font-weight: 500;
`;

export const ActivityContent = styled('div')`
   
`;

export const ActivityList = styled('ol')`
    list-style-type: lower-alpha;
`;

export const ActivityListElement = styled('li')`
   margin-left: ${props => props.marginLeft ?? '3rem'};
   
   p {
       margin: 0;
   }
`;

export const CorrectionSymbol = styled('p')`
    margin: 0 0 0 1.5rem; 
    color: ${props => props.isRight === 'TRUE' ? 'green' : 'red'};
`

export const ButtonsContainer = styled('div')`
   display: flex;
   justify-content: space-around;
   gap: 16px;
`;

export const ActivityImage = styled('img')`
   max-height: 200px;
   margin: 0.5em auto;
`;

export const OptionImage = styled(ActivityImage)`
   max-height: 200px;
   margin: ${props => props.hasTitle ? '0 auto 0 3em' : '0 auto 0 0'};
`

export const SwitchContainer = styled('div')`
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 12px 16px;
    margin-right: 4em;
    background-color: #fff;
    box-shadow: 0px 1px 2px -1px rgb(249 251 252 / 10%), 0px 2px 5px 0px rgb(249 251 252 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    border-radius: 8px;
    width: 60em;
`

export const CorrectAnswer = styled('p')`
    color: #95cda9;
    font-weight: bold;
    font-size: 1.25rem;
`

export const ContainerButtonsMove = styled('div')`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    margin-bottom: 8px;
`
export const ButtonMove = styled(XButton)`
    padding: 5px !important;
    margin: 0 !important;
    min-width: min-content;
    
`

export const StyledXLabel = styled(XLabel)`
    width: -webkit-fill-available;
    justify-content: space-between;

    .css-tbfrkj {
        margin: unset;
    }
`