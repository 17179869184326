import { styled } from '@mui/system'

export const StyledDiv = styled('div')`
    box-shadow: 0px 1px 2px -1px rgb(249 251 252 / 10%), 0px 2px 5px 0px rgb(249 251 252 / 14%), 0px 1px 10px 0px rgb(229 27 35) !important;
    padding: 8px;
    position: absolute;
    right: 16px;
    bottom: 16px;
    background: #fff;
    width: 50vw;
`